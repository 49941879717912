<template>
    <div>
        <vehicle :typeArea="2"></vehicle>
    </div>
</template>

<script>
import Vehicle from '@/views/Management/Vehicle/Vehicle.vue'
export default {
    components: {
        Vehicle
    }
}
</script>

<style>

</style>